import http from "../http-common";

class FacturacionSerive{
    async checkPedido( data ){
        const result = await http.post( '/facturas/check-pedido', data, {
            
        })
        return result.data;
    }
    async createInvoice( data ){
        const result = await http.post( '/facturas/create-invoice', data, {
            responseType: 'blob'
        })
        return result.data;
    }
}

export default new FacturacionSerive;